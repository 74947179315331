// AWS Project Constants
const awsmobile = {
    Auth: {
        identityPoolId: 'us-west-2:12a2e0f1-0cad-4057-8f2f-c7d0a0c78fbf',
        region: 'us-west-2',
        userPoolId: 'us-west-2_jYbpPQwZ9',
        userPoolWebClientId: '2u268vnthh8pc0vv1gmhmohue8',
    },
    API: {
        endpoints: [
            {
                name: 'TenMinSavesAPI',
                endpoint: 'https://api.tenminutesaves.com',
                region: 'us-west-2'
            }
        ]
    }
}
  
export default awsmobile;